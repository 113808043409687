
		var stache = require('can-stache');
		var mustacheCore = require( "can-stache/src/mustache_core" );
		var parse = require("can-stache-ast").parse;
		//common deps
		require('can-view-import');
		require('can-stache-bindings');
	
		require('~/components/run-list/run-list');
		
		var renderer = stache("/tmp/build_55d86039/public/components/year/year.stache", [{"tokenType":"start","args":["can-import",true]},{"tokenType":"attrStart","args":["from"]},{"tokenType":"attrValue","args":["~/components/run-list/run-list"]},{"tokenType":"attrEnd","args":["from"]},{"tokenType":"end","args":["can-import",true]},{"tokenType":"chars","args":[" "]},{"tokenType":"special","args":["#for(month of eventsByMonth)"]},{"tokenType":"chars","args":["\n  "]},{"tokenType":"start","args":["h3",false]},{"tokenType":"end","args":["h3",false]},{"tokenType":"special","args":["month.name"]},{"tokenType":"chars","args":[" "]},{"tokenType":"special","args":["month.year"]},{"tokenType":"close","args":["h3"]},{"tokenType":"chars","args":["\n  "]},{"tokenType":"start","args":["lbh3-run-list",true]},{"tokenType":"attrStart","args":["events:from"]},{"tokenType":"attrValue","args":["month.events"]},{"tokenType":"attrEnd","args":["events:from"]},{"tokenType":"attrStart","args":["show:u:hashit:from"]},{"tokenType":"attrValue","args":["showHashit"]},{"tokenType":"attrEnd","args":["show:u:hashit:from"]},{"tokenType":"attrStart","args":["show:u:notes:from"]},{"tokenType":"attrValue","args":["showNotes"]},{"tokenType":"attrEnd","args":["show:u:notes:from"]},{"tokenType":"attrStart","args":["show:u:on:u:on:from"]},{"tokenType":"attrValue","args":["showOnOn"]},{"tokenType":"attrEnd","args":["show:u:on:u:on:from"]},{"tokenType":"attrStart","args":["show:u:scribe:from"]},{"tokenType":"attrValue","args":["showScribe"]},{"tokenType":"attrEnd","args":["show:u:scribe:from"]},{"tokenType":"end","args":["lbh3-run-list",true]},{"tokenType":"chars","args":["\n"]},{"tokenType":"special","args":["/for"]},{"tokenType":"chars","args":["\n\n"]},{"tokenType":"done","args":[]}])

        module.exports = function(scope, options, nodeList) {
			var moduleOptions = Object.assign({}, options);
			
            if(moduleOptions.helpers) {
                moduleOptions.helpers = Object.assign({ module: module }, moduleOptions.helpers);
            } else {
                moduleOptions.module = module;
			}
            return renderer( scope, moduleOptions, nodeList );
        };
    